@include font-face(Michroma, fonts/michroma-v8-latin-regular);
@include font-face(Montserrat, fonts/montserrat-v12-latin-regular, 500);

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-family: 'Michroma';
    text-align: center;
    margin-bottom: 1em;
    -moz-hyphens: auto;
    -o-hyphens: auto;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
}

.navbar {
    background-color: rgba(255, 136, 0, 0.75) !important;
}

.breadcrumb {
    padding: 0;
    /* position: absolute;
    width: 100%; */
    padding-left: 0px;
    /* padding-left: 4rem; */
}

.breadcrumb .items {
    position: relative;
    /* left: 1rem; */
    font-size: small;
}

.breadcrumb .item {
    margin-left: 0;
    margin-right: 0.2rem;
}

div.navbar-header.search {
    padding-left: 1.75rem;
}

.navbar-flags {
    padding-top: 0.25rem;
    padding-left: 10px;
}

.navbar-flags img {
    height: 1rem;
}

.navbar-brand img {
    margin-top: -3px;
}

#navbarNavDropdown {
    /*transform: scale(0.8,1); */
    /* text-transform: uppercase; */
    font-weight: bolder;
}

#navbarNavDropdown .dropdown-item {
    font-weight: bolder;
}

body strong {
    font-weight: bolder;
    text-shadow: 0px 1px, 1px 0px, 1px 1px;
}

.dropdown-menu {
    background-color: rgba(255, 136, 0, 0.95) !important;
}

/* change the color of active or hovered links */
.navbar-custom .nav-item.active .nav-link,
.navbar-custom .nav-item:hover .nav-link {
    color: #000;
    background-color: rgba(255, 136, 0, 0.95);
}

a,
a:hover,
.article-list a,
.header a,
footer a,
.categories a,
.active a,
.comments a {
    color: #339;
    text-decoration: none;
}

p.movementcontent a,
div.donation a {
    color: #00f;
    font-weight: bolder;
}

p.movementcontent a:hover,
div.donation a:hover {
    text-decoration: underline;
}

p.movementcontent a:active,
div.donation a:active {
    background-color: #00f;
    color: #fff;
}

p.movementcontent.watchdescription {
    width: 250px;
}

a[href^="http"]:not(.noExt):after {
    content: "\f08e";
    font-family: FontAwesome;
    padding-left: 3px;
}

#copyright {
    margin-bottom: 1rem;
}

/* Very generic styling elements */
div.leftblock {
    margin: auto;
    text-align: left;
}

div.rightblock {
    margin: auto;
    text-align: right;
}

div.centerblock {
    margin: auto;
    text-align: center;
}

div.center {
    text-align: center;
}

div.donation {
    text-align: center;
    margin-top: 3em;
}


div.backgroundimg {
    position: relative;
    top: -6em;
    opacity: 0.3;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: -50%;
}

.centerimg {
    text-align: center;
}

div.alert {
    background-color: yellow;
    font-weight: bold;
    font-size: larger;
    border: medium;
    border-color: red;
    border-style: solid;
    text-align: justify;
}

div.alert strong {
    font-weight: 900;
    text-shadow: 1px 1px #000;
}

p.blocksatz {
    text-align: justify;
}

div.legal h2 {
    text-align: left;
}

div.legal h3 {
    text-align: left;
}

.article-list h3 {
    margin-top: 0px;
}

#homepage h1 {
    margin-top: 1rem;
}

#heading-breadcrumbs {
    margin-top: 3rem;
}

.back-to-top {
    cursor: pointer;
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: none;
}

.highlight {
    background-color: rgba(255, 136, 0, 0.25);
    color: black;
    font-weight: bold;
}

.searchtitle {
    font-weight: bold;
    font-size: 120%;
}

/* Weitere Werke */

.relatedmovements h3 {
    font-size: 1.2rem;
}

.relatedmovements .card-header {
    padding-bottom: 0px !important;
}

.relatedmovements .card-title {
    padding-bottom: 0px !important;
}

h3.card-title {
    font-size: 1.1rem;
    font-weight: bolder;
}

.relatedmovements a.nav-link {
    padding: 0;
}

/* Kaliberfamilie */
.card.caliberfamily h3 {
    font-size: 1.2rem;
}

.card.caliberfamily .card-header {
    padding-bottom: 0px !important;
}

.card.caliberfamily .card-title {
    padding-bottom: 0px !important;
}

.card.caliberfamily {
    margin-bottom: 2rem;
}

.card.caliberfamily a.nav-link {
    padding: 0;
    display: inline;
}

.card.caliberfamily li.nav-item {
    line-height: 1.0;
    margin-bottom: 0.5rem;
}

/* Article teaser styling */
.teaser span {
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    line-height: 2em;
    font-family: 'Michroma';
    text-align: left;
}

.teaser .bold {
    font-weight: bold;
    padding-left: 10px;
    padding-right: 10px;
    white-space: nowrap;
}

.teaser span.top {
    display: block;
    font-weight: lighter;
    font-size: small;
    position: absolute;
    top: 0em;
    height: 2em;
    padding-left: 10px;
    background-color: silver;
    background-color: hsla(0, 0%, 100%, 0.8);
}

.teaser span.headline {
    position: absolute;
    height: 6em;
    background-color: silver;
    background-color: hsla(0, 0%, 100%, 0.8);
}

.teaser span.text {
    padding-left: 10px;
}

.teaser section {
    height: 22em !important;
    overflow-x: unset !important;
}

.teaser div {
    height: 20em !important;
    position: relative;
}

/* Movement related elements */
h1.movementtitle {
    padding-bottom: -1em !important;
}

/* Movement related elements */
h1.movementsubtitle {
    margin-bottom: 0.5rem !important;
}

h2.movementsubtitle {
    padding-bottom: -2rem !important;
    padding-top: -1rem !important;
    font-size: 1.5rem;
}

h2.movementcontent {
    padding-top: 1em;
}

div.movementtitle {
    height: 9em;
}

div.movementsubtitle {
    height: 10rem;
}

p.movementcontent {
    text-align: justify;
}

#movementlist {
    font-size: medium;
}

#movementlist th {
    padding-top: 2px;
    padding-bottom: 2px;
}

#movementlist td {
    padding-top: 2px;
    padding-bottom: 2px;
}

#movementlist .caliber {
    vertical-align: middle;
}

#movementlist .movement-unavailable {
    font-weight: 100;
    opacity: 0.5;
}

#movementlist .movement-available {
    font-weight: 900;
}

.movementalternative {
    position: relative;
    top: -0.5rem;
    font-size: small;
    margin-bottom: -0.5rem;
}

#movementlist .article-available {
    padding: 0 0 0 0.2rem !important;
    margin: 0 0 0 0 !important;
}

#movementlist .doc-available {
    padding: 0 0 0 0.2rem !important;
    margin: 0 0 0 0 !important;
}

table.timegrapher {
    width: inherit;
}

div.timegrapher {
    text-align: center;
    margin: auto;
    display: inline-block;
    overflow: hidden;
    box-shadow: 0 0 8px #666;
    padding: 4px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 30rem;
}

th.timegrapher-head {
    padding-top: 0;
}

th.timegrapher-row {
    text-align: left;
}

.timegrapher th {
    text-align: left;
}

thead.timegrapher.thead-dark {
    height: 1rem;
}

div.timex {
    background: none repeat scroll 0 0 #FFFFFF;
    border: 4px solid #FFFFFF;
    display: inline;
    float: left;
    margin-bottom: 20px;
    width: 243px;
    font-size: 10pt;
    line-height: 1.4em;
    vertical-align: top;
    flex: 1;
    /* additionally, equal width */
}

.timex div.number {
    display: inline;
    float: left;
    font-weight: bold;
    text-align: left;
}

.timex div.movement {
    font-size: 8pt;
    display: inline;
    float: left;
    font-weight: normal;
    left: 13px;
    position: relative;
    text-align: center;
}

.timex div.cost {
    display: inline;
    float: right;
    font-weight: bold;
    text-align: right;
}

.timex div.body {
    background: none repeat scroll 0 0 #FFFFFF;
}

div.specification {
    text-align: left;
    margin: auto;
    display: inline-block;
    overflow: hidden;
    box-shadow: 0 0 8px #666;
    padding: 4px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
}

table.specification {
    background-color: #eee;
}

table.specification tr:nth-child(even) {
    background-color: #ddd;
}

table.specification td#first {
    padding-top: 5px;
}

table.specification td {
    padding-left: 5px;
    padding-right: 5px;
}

table.specification td div ul {
    list-style-position: inside;
    padding-left: 0;
    margin-bottom: 0;
}

table.specification td.key {
    font-weight: bold;
    vertical-align: top;
}

table.specification td.caliberid {
    font-weight: 900;
}

table.specification div.alternative {
    margin-top: 1em;
    margin-bottom: 1em;
    text-align: center;
    font-style: italic;
}

table.specification caption {
    caption-side: bottom;
    text-align: center;
    font-size: small;
    padding: 2px;
}

/* Figure and thumbnail related stuff */
figure.einzelbild {
    display: inline-block;
    padding: 10px;
    overflow: hidden;
    margin: 5px 8px 5px 0;
    box-shadow: 0 0 8px #666;
    padding: 4px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
}

figure.einzelbild figcaption {
    text-align: center;
    margin-top: 0.5rem;
    margin-bottom: -0.65rem;
    font-size: small;
}

figure.einzelbild img {
    margin-right: auto;
    margin-left: auto;
    display: block;
    object-fit: cover;
}

figure.mainimage {
    text-align: center;
}

figure.timegrapher {
    width: 200px;
    display: inline-block;
    margin-right: 10px;
}

figcaption p.blocksatz {
    text-align: center;
}

figcaption p.movementcontent {
    text-align: center;
}

.thumbnail {
    height: 9em;
    float: left;
    overflow: hidden;
    margin: 5px 8px 5px 0;
    box-shadow: 0 0 8px #666;
}

.thumbnail img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* List related styles */
.list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
}

.list section {
    flex: 1 0 350px;
    box-sizing: border-box;
    box-shadow: 0 0 8px #ccc;
    margin: 1em 0 0 15px;
    padding: 1em;
    overflow-x: hidden;
    max-width: 300px;
}

.knowledge .list-common .card-title {
    margin-top: -1rem;
    height: unset;
    margin-bottom: 1rem;
}

/* Teaserboxen Homepage */
.card.newmovement {
    min-width: 250px;
    max-width: 250px;
    margin-bottom: 2rem;
    font-family: Michroma;
    -ms-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

.card.newmovement .card-img {
    object-fit: cover;
    /* Do not scale the image */
    object-position: center;
    /* Center the image within the element */
    height: 248px;
    width: 248px;
}

.card.newmovement .card-title {
    height: 3rem;
}

.card.newmovement h5 {
    font-size: 1.0rem;
    font-weight: bold;
    text-align: left;
}

.card-deck.newmovement {
    justify-content: center;
}

.card.newmovement .card-body .card-text {
    padding-bottom: 1rem;
    font-family: Montserrat;
}

.card.newmovement .card-body .btn {
    vertical-align: bottom;
    position: absolute;
    bottom: 0.25rem;
}

/* Teaserboxen Allgemein */
.card.list-common {
    min-width: 250px;
    max-width: 250px;
    margin-bottom: 2rem;
    font-family: Michroma;
}

.list-common img.preview {
    object-fit: cover;
    /* Do not scale the image */
    object-position: center;
    /* Center the image within the element */
    height: 248px;
    width: 248px;
    margin-bottom: 1rem;
}

.list-common .card-title {
    height: 5rem;
}

.list-common .card-body {
    text-align: justify;
    font-family: Montserrat;
    margin-bottom: 1rem;
}

.list-common .card-body .btn {
    vertical-align: bottom;
    position: absolute;
    bottom: 0.25rem;
    font-family: Michroma;
}

div.glossary {
    margin-top: 2rem;
    background-color: #ddd;
    border: #aaa;
    border-style: groove;
}

.glossary table,
.glossary td,
.glossary th {
    border: 1px solid black;
    border-spacing: 0;
    margin-top: -1rem;
}

.glossary th {
    border-bottom: 0px;
}

.glossary td {
    border-top: 0px;
    padding-top: 2rem;
}

.glossary h3 {
    margin-bottom: 0.2rem;
}

.preamble {
    font-size: smaller;
    margin-left: 5rem;
    margin-top: -1rem;
    margin-bottom: 1rem;
}

/* Spinner */
.loader {
    border: 16px solid #f3f3f3;
    /* Light grey */
    border-top: 16px solid orange;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
    margin-left: auto;
    margin-right: auto;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.shockprotections {
    display: inline-flex;
    flex-wrap: wrap;
}

.flex-direction {
    flex-direction: row;
}

div.card.shockprotection {
    width: 150px;
    margin: 10px;
    box-shadow: 0 0 8px #666;
    padding-top: 0.25rem;
}

.shockprotection .card-img-top {
    padding-left: 25px;
    padding-right: 25px;
}

.shockprotection .card-body {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
}

.shockprotection p.card-text {
    font-family: Helvetica;
    font-stretch: ultra-condensed;
}

.shockprotection h5.card-title {
    font-family: Helvetica;
    font-weight: bold;
}

.ribbon {
    position: absolute;
    right: -5px;
    top: -5px;
    z-index: 1;
    overflow: hidden;
    width: 85px;
    height: 85px;
    text-align: right;
}

.ribbon span {
    font-size: 10px;
    font-weight: bold;
    color: #FFF;
    text-transform: uppercase;
    text-align: center;
    line-height: 20px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    width: 120px;
    display: block;
    background: #79A70A;
    background: linear-gradient(#F79E05 0%, #8F5408 100%);
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    position: absolute;
    top: 23px;
    right: -21px;
}

.ribbon span::before {
    content: "";
    position: absolute;
    left: 0px;
    top: 100%;
    z-index: -1;
    border-left: 3px solid #8F5408;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #8F5408;
}

.ribbon span::after {
    content: "";
    position: absolute;
    right: 0px;
    top: 100%;
    z-index: -1;
    border-left: 3px solid transparent;
    border-right: 3px solid #8F5408;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #8F5408;
}